import React, { useEffect, useRef, useState } from "react";
import { Stage, Layer, Image, Text, Transformer } from "react-konva";
import useImage from "use-image";

import dexscreener from "./assets/dexscreener.png";
import telegram from "./assets/telegram.png";
import twitter from "./assets/twitter.png";

import chiliguytext from "./assets/chiliguytext.png";
import plus from "./assets/plus.png";

import backgroundImage from "./assets/backgroundImage.png";
import chiliguy from "./assets/chiliguy.png";
import startingBackground from "./assets/startingBackground.jpg";

import "./App.css";


function App() {
  const stageRef = useRef(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [showChiliGuy, setShowChiliGuy] = useState(false);
  const [textItems, setTextItems] = useState([]); // List of text items on the canvas
  const [selectedNode, setSelectedNode] = useState(null); // Keep track of the selected Konva node
  const [startingBackgroundImage] = useImage(startingBackground); // Preload the starting background image
  const [isMobile, setIsMobile] = useState(false);
  const [textInput, setTextInput] = useState("just a chiliguy"); // Input for text to be added

  const canvasWidth = isMobile ? 300 : 500;
  const canvasHeight = isMobile ? 300 : 500;

  // Preload the ChiliGuy image
  const [chiliguyImg] = useImage(chiliguy);

  // Preload the uploaded background image
  const [backgroundImg] = useImage(uploadedImage);

  // Add a new text item to the canvas
  const addText = () => {
    if (textInput.trim() !== "") {
      const newText = {
        id: `text-${Date.now()}`,
        text: textInput,
        x: 50,
        y: 50,
        fontSize: 24,
        draggable: true,
      };
      setTextItems([...textItems, newText]);
      isMobile ? setTextInput("") : setTextInput("im just a chiliguy"); // Clear the input field after adding text
    }
  };

  const uploadBackground = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setUploadedImage(reader.result); // Set uploaded image as base64
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  // Handle resizing for mobile view
  useEffect(() => {
    const updateView = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    updateView();
    window.addEventListener("resize", updateView);
    return () => window.removeEventListener("resize", updateView);
  }, []);

  // Attach Transformer to the selected node
  const TransformerComponent = () => {
    const transformerRef = useRef();

    useEffect(() => {
      if (selectedNode) {
        transformerRef.current.nodes([selectedNode]);
        transformerRef.current.getLayer().batchDraw();
      } else {
        transformerRef.current.nodes([]);
      }
    }, [selectedNode]);

    return <Transformer ref={transformerRef} />;
  };

  const downloadMeme = () => {
    if (!stageRef.current) return;

    // Deselect all objects and disable transformers
    const stage = stageRef.current;
    const transformers = stage.find("Transformer");
    transformers.forEach((transformer) => transformer.detach());
    stage.draw(); // Redraw stage without selection boxes

    // Generate the data URL
    const uri = stage.toDataURL();

    // Reattach transformers (optional, to restore functionality after download)
    transformers.forEach((transformer) => {
      const targetNodeId = transformer._nodes?.[0]?.attrs?.id; // Find the associated node
      const targetNode = stage.findOne(`#${targetNodeId}`);
      if (targetNode) {
        transformer.attachTo(targetNode);
      }
    });
    stage.draw(); // Redraw stage to restore selection boxes (optional)

    // Download the image
    const link = document.createElement("a");
    link.download = "meme.png";
    link.href = uri;
    link.click();
  };

  return (
    <div
      style={{
        backgroundImage: isMobile ? "none" : `url(${backgroundImage})`,
        backgroundColor: isMobile ? "#879076" : "transparent",
        backgroundSize: "cover",
      }}
      className="flex-col items-center bg-gray-200 min-h-screen py-8"
    >
      {/* Button Section */}
      <div
        className={`flex ${
          isMobile ? "flex-col items-center" : "text-center space-x-4"
        } mb-6`}
      >
        <div
          className={`flex ${isMobile ? "justify-center" : "ml-10"} bg-white pl-5 pr-5`}
        >
          <img src={twitter} onClick={() => window.open('https://x.com/justachiliguy')} className="m-2 w-[2rem] h-[2rem] hover:cursor-pointer" alt="Twitter" />
          <img src={dexscreener} onClick={() => window.open('https://x.com/justachiliguy')} className="m-2 w-[2rem] h-[2rem] hover:cursor-pointer" alt="Dexscreener" />
          <img src={telegram} onClick={() => window.open('https://t.me/ChiliGuyPortal')} className="m-2 w-[2rem] h-[2rem] hover:cursor-pointer" alt="Telegram" />
        </div>
      </div>

      {/* Meme Section */}
      <div className="flex w-full justify-center ml-15 mr-15">
        <div
          className={`flex flex-col justify-center items-center ${
            isMobile ? "w-[300px]" : "w-1/2"
          } ml-10 mr-10 mt-10`}
        >
          <div
            className={`flex ${
              isMobile ? "flex-col items-center space-y-2" : "space-x-4 items-center justify-center"
            } w-full mb-5`}
          >
            <button
              onClick={() => setShowChiliGuy(true)}
              className={`bg-chiligreen text-black font-bold py-2 rounded hover:bg-green-600 transition ${
                isMobile ? "w-full" : "px-4"
              }`}
            >
              ADD CHILIGUY
            </button>
            <button
              onClick={uploadBackground}
              className={`bg-chiliyellow text-black font-bold py-2 rounded hover:bg-green-600 transition ${
                isMobile ? "w-full" : "px-4"
              }`}
            >
              UPLOAD BACKGROUND
            </button>
            {!isMobile && (
              <button
                onClick={addText}
                className={`bg-chilired text-black font-bold py-2 rounded hover:bg-red-600 transition ${
                  isMobile ? "w-full" : "px-4"
                }`}
              >
                ADD TEXT
              </button>
            )}
            {isMobile && (
              <>
                <input
                  type="text"
                  placeholder="Enter text to add"
                  value={textInput}
                  onChange={(e) => setTextInput(e.target.value)}
                  className="border border-gray-300 rounded p-2 mb-2 w-full"
                />
                <button
                  onClick={addText}
                  className="bg-chilired text-black font-bold py-2 rounded hover:bg-red-600 transition w-full"
                >
                  ADD TEXT
                </button>
              </>
            )}
          </div>

          {/* Konva Stage */}
          <Stage
            ref={stageRef}
            width={canvasWidth}
            height={canvasHeight}
            className="border"
            style={{ backgroundColor: "#f0f0f0" }}
            onClick={(e) => {
              if (e.target === stageRef.current) {
                setSelectedNode(null); // Deselect on background click
              }
            }}
          >
            <Layer>
              {/* Starting Background */}
              {startingBackgroundImage && (
                <Image
                  image={startingBackgroundImage}
                  width={canvasWidth}
                  height={canvasHeight}
                />
              )}

              {/* Uploaded Background */}
              {backgroundImg && (
                <Image
                  image={backgroundImg}
                  width={canvasWidth}
                  height={canvasHeight}
                />
              )}

              {/* Chili Guy */}
              {showChiliGuy && chiliguyImg && (
                <Image
                  image={chiliguyImg}
                  x={100}
                  y={100}
                  draggable
                  width={isMobile ? 125 : 200}
                  height={isMobile ? 200 : 320}
                  onClick={(e) => {
                    setSelectedNode(e.target);
                    e.cancelBubble = true;
                  }}
                />
              )}

              {/* Text Items */}
              {textItems.map((item) => (
                <Text
                  key={item.id}
                  id={item.id}
                  text={item.text}
                  x={item.x}
                  y={item.y}
                  fontSize={item.fontSize}
                  fill="black"
                  draggable
                  onClick={(e) => {
                    setSelectedNode(e.target);
                    e.cancelBubble = true;
                  }}
                  onDblClick={(e) => {
                    const textNode = e.target;
                    const newText = prompt("Edit text:", textNode.text());
                    if (newText !== null) {
                      textNode.text(newText);
                      setTextItems((prev) =>
                        prev.map((t) =>
                          t.id === textNode.id() ? { ...t, text: newText } : t
                        )
                      );
                    }
                  }}
                />
              ))}

              {/* Transformer */}
              <TransformerComponent />
            </Layer>
          </Stage>

          {/* Upload and Download Buttons */}
          <div
            className={`flex ${
              isMobile ? "flex-col items-center space-y-2 w-full" : "space-x-4 w-1/2"
            } mt-5`}
          >
            <button
              onClick={downloadMeme}
              className="bg-chiliblue text-white font-bold py-3 rounded hover:bg-blue-600 transition w-full"
            >
              DOWNLOAD MEME
            </button>
          </div>

          {/* Footer */}
          <div className={`flex-col mt-2 text-center items-center`}>
            <img src={chiliguytext} alt="Chili Guy Text" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
